import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export function convertDuration(duration) {
    const matches = duration.match(/\d+/g);
    if (!matches) {
        return 0;
    }
    const [hours, minutes] = matches;
    return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
}

export function formatDateExcel(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);  // Création de l'objet Date
}

export function parseNumber(numberString) {
    // Vérifier si numberString est une chaîne, sinon le convertir en chaîne
    if (typeof numberString !== 'string') {
        numberString = numberString.toString();
    }
    return Number(numberString.replace(/[^\d.-]/g, ''));  // Enlever les caractères non numériques et convertir
}

export function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getMonthNumber(date) {
    return date.getMonth() + 1;
}

export function formatDateModal(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
}

export function formatHeureModal(dateOrString) {
    if (typeof dateOrString === "string") {
        return dateOrString; // Retourne directement la chaîne si elle est déjà au format "HH:MM"
    }

    if (dateOrString instanceof Date) {
        const hours = dateOrString.getHours().toString().padStart(2, "0");
        const minutes = dateOrString.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`; // Formatte la Date en "HH:MM"
    }

    return "00:00"; // Par défaut, retourne un format vide en cas d'erreur
}

export const formatTimeWithTimezone = (dateOrString) => {
    if (typeof dateOrString === "string") {
        return dateOrString; // Si déjà une chaîne "HH:MM", retourne directement
    }

    if (dateOrString instanceof Date) {
        const hours = dateOrString.getHours().toString().padStart(2, "0");
        const minutes = dateOrString.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    return "00:00"; // Retourne un format par défaut en cas d'erreur
}

export const formatDateForBackend = (date) => {
    if (!(date instanceof Date)) date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois indexé à partir de 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export async function getNameByDateAndSerial(date, serial) {
    if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        try {
            const res = await axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            });

            const deviceName = res.data.sort((a, b) => a.username.localeCompare(b.username));
            if (deviceName.length > 0) {
                const device = deviceName.find(device => device.username === serial);
                let selectedDevice = "noname";

                if (device !== undefined) {
                    let NameDevice;

                    try {
                        NameDevice = JSON.parse(device.name_device);
                    } catch (error) {
                        console.error("Erreur de parsing JSON:", error);
                        return selectedDevice;
                    }

                    if (!Array.isArray(NameDevice)) {
                        NameDevice = [NameDevice];
                    }

                    const StartDate = new Date(date);
                    StartDate.setHours(23, 59, 59, 999);

                    const flatten = (input) => {
                        const result = [];
                        input.forEach(item => {
                            if (Array.isArray(item)) {
                                result.push(...flatten(item));
                            } else if (item && typeof item === 'object' && 'timestamp' in item) {
                                result.push(item);
                            }
                        });
                        return result;
                    };

                    const flattenedNameDevice = flatten(NameDevice);

                    if (flattenedNameDevice.length > 0) {
                        flattenedNameDevice.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                        for (const item of flattenedNameDevice) {
                            const itemDate = new Date(item.timestamp);

                            if (itemDate <= StartDate) {
                                selectedDevice = item.nameDevice;
                            } else {
                                break;
                            }
                        }
                    }
                }

                let str = selectedDevice;
                if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                    str = `${str}(${serial})`;
                }
                return str;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des devices:", error);
            return "Erreur";
        }
    }
    else {
        try {
            const res = await axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            });

            const deviceName = res.data.sort((a, b) => a.username.localeCompare(b.username));
            if (deviceName.length > 0) {
                const device = deviceName.find(device => device.username === serial);
                let selectedDevice = "noname";

                if (device !== undefined) {
                    let NameDevice;

                    try {
                        NameDevice = JSON.parse(device.name_device);
                    } catch (error) {
                        console.error("Erreur de parsing JSON:", error);
                        return selectedDevice;
                    }

                    if (!Array.isArray(NameDevice)) {
                        NameDevice = [NameDevice];
                    }

                    const StartDate = new Date(date);
                    StartDate.setHours(23, 59, 59, 999);

                    const flatten = (input) => {
                        const result = [];
                        input.forEach(item => {
                            if (Array.isArray(item)) {
                                result.push(...flatten(item));
                            } else if (item && typeof item === 'object' && 'timestamp' in item) {
                                result.push(item);
                            }
                        });
                        return result;
                    };

                    const flattenedNameDevice = flatten(NameDevice);

                    if (flattenedNameDevice.length > 0) {
                        flattenedNameDevice.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                        for (const item of flattenedNameDevice) {
                            const itemDate = new Date(item.timestamp);

                            if (itemDate <= StartDate) {
                                selectedDevice = item.nameDevice;
                            } else {
                                break;
                            }
                        }
                    }
                }

                let str = selectedDevice;
                if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                    str = `${str}(${serial})`;
                }
                return str;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des devices:", error);
            return "Erreur";
        }
    }
    return "noname"; // Fallback si aucun rôle ADMIN
}
