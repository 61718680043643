import Dashboard from "../components/admin/DashBoard";
import Terminal from "../components/admin/Terminal";
import UserDashboard from "../components/user/DashboardUser";
import Replay from "../components/user/Replay/Replay";
import EditWorksite from "../components/user/EditWorksite/EditWorksite";
import FleetVisualization from "../components/user/Fleet/FleetVisualization";
import Device from "../components/user/Device/Device";
import DeviceFilesTransfer from "../components/admin/DeviceFilesTransfer";
import Setting from "../components/user/Setting/Setting";
import EnginUpdate from "../components/admin/EnginUpdate";
import DashboardSecurite from "../components/user/DashboardSecurite/DashboardSecurite";
import DashboardMaintenance from "../components/user/DashboardMaintenance";
import MovingDanger from "../components/user/MovingDanger";
import Rotation from "../components/user/Rotation/Rotation";
import FilteredMovingDanger from "../components/user/FilteredMovingDanger";
import NotFound from "../404";
import sdExplorer from "../components/admin/SdExplorer";
import DashboardProduction from "../components/user/DashboardProduction/DashboardProduction";
import DashboardProductionCumulative from "../components/user/DashboardProduction/DashboardProductionCumulative";
import ManageRotation from "../components/user/ManageRotation/ManageRotation";
import ManageRotationV2 from "../components/user/ManageRotation/ManageRotationV2"
import HeatMapSpeed from "../components/user/HeatMap/HeatMapSpeed";
import HeatMapAlertPedestrian from "../components/user/HeatMap/HeatMapAlertPedestrian";
import HeatMapAlertHeavyWeight from "../components/user/HeatMap/HeatMapAlertHeavyWeight";
import HeatMapAlertVehicle from "../components/user/HeatMap/HeatMapAlertVehicle";
import HeatMapAlertMachine from "../components/user/HeatMap/HeatMapAlertMachine";
import CommentPage from "../components/admin/Comment/CommentPage";
import Worksite from "../components/admin/Worksite";
import Sim from "../components/admin/Sim";
import testK1 from "../components/admin/testK1";
import InfoDevice from "../components/admin/InfoDevice";
import BTDevice from "../components/admin/BTDevice";
import DangersFixes from "../components/admin/DangersFixes";
import ReflashVisualization from "../components/admin/ReflashVisualization";
import User from "../components/admin/User";
import DashboardProductionV2 from "../components/user/DashboardProduction/DashboardProductionV2";
import DashboardProductionStop from "../components/user/DashboardProduction/DashboardProductionStop";

export let dashboardRoutes = [];

dashboardRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa-solid fa-truck-pickup",
        component: Dashboard,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/FleetVisualization",
        name: "Fleet",
        icon: "fa-solid fa-location-dot",
        component: FleetVisualization,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/dashboardUser",
        name: "User Dashboard",
        icon: "fa-solid fa-truck-pickup",
        component: UserDashboard,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/DashboardSecurite",
        name: "Safety",
        icon: "fa-solid fa-triangle-exclamation",
        component: DashboardSecurite,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/DashboardProduction",
        name: "Production",
        icon: "fa-solid fa-chart-column",
        component: DashboardProduction,
        layout: "/admin",
        subMenuItems: [
            { layout: '/admin', path: '/dashboard/production/daily', icon: 'fa-solid fa-calendar', name: 'Daily', component: DashboardProductionV2, construction: false },
            { layout: '/admin', path: '/dashboard/production/cumulative', icon: 'fa-solid fa-layer-group', name: 'Cumulative', component: DashboardProductionCumulative },
            // { layout: '/admin', path: '/dashboard/production/pausepanel', icon: 'fa-regular fa-clock', name: 'Arret', component: DashboardProductionStop, construction: false, invisible: true },
        ],
    },
    {
        invisible: true,
        path: "*",
        name: "Not Found",
        icon: "",
        component: NotFound,
        layout: ""
    },
    {
        invisible: true,
        path: "/Terminal/:serial",
        name: "Terminal",
        icon: "",
        component: Terminal,
        layout: "/admin",
    },
    {
        invisible: true,
        path: "/ReflashVisualization/:serial/:serialP1",
        name: "ReflashVisualization",
        icon: "",
        component: ReflashVisualization,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/InfoDevice/:serial",
        name: "InfoDevice",
        icon: "",
        component: InfoDevice,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/SdExplorer/:serial",
        name: "SdExplorer",
        icon: "",
        component: sdExplorer,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/Worksite/:serial",
        name: "Worksite",
        icon: "",
        component: Worksite,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/EnginBT/:serial",
        name: "Engin",
        icon: "",
        component: BTDevice,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/DangersFixes/:serial",
        name: "DangersFixes",
        icon: "",
        component: DangersFixes,
        construction: false,
        layout: "/admin"
    },
    {
        path: "/replay",
        name: "Replay",
        icon: "fa-solid fa-play",
        component: Replay,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/Device",
        name: "Device",
        icon: "fa-solid fa-tablet-button",
        component: Device,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/User",
        name: "User",
        icon: "fa-solid fa-user",
        component: User,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/EnginUpdate/:serial",
        name: "EnginUpdate",
        icon: "nc-icon nc-chart-pie-35",
        component: EnginUpdate,
        layout: "/admin"
    },
    {
        path: "/deviceFilesTransfer",
        name: "device Files Transfer",
        icon: "fa-solid fa-cloud-arrow-up",
        component: DeviceFilesTransfer,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        path: "/Settings",
        name: "Settings",
        icon: "fa-solid fa-gear",
        component: Setting,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/map",
        name: "EditWorksite",
        icon: "nc-icon nc-chart-pie-35",
        component: EditWorksite,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/MovingDanger",
        name: "MovingDanger",
        icon: "nc-icon nc-chart-pie-35",
        component: MovingDanger,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/Rotation",
        name: "Rotation",
        icon: "nc-icon nc-chart-pie-35",
        component: Rotation,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/FilteredMovingDanger",
        name: "Filtered Moving Danger",
        icon: "nc-icon nc-chart-pie-35",
        component: FilteredMovingDanger,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/ManageRotationV2",
        name: "ManageRotationV2",
        icon: "nc-icon nc-chart-pie-35",
        component: ManageRotationV2,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/HeatMapSpeed",
        name: "HeatMapSpeed",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapSpeed,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/HeatMapPedestrian",
        name: "HeatMapPedestrian",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertPedestrian,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/HeatMapHeavyWeight",
        name: "HeatMapHeavyWeight",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertHeavyWeight,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/HeatMapVehicle",
        name: "HeatMapVehicle",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertVehicle,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/HeatMapMachine",
        name: "HeatMapMachine",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertMachine,
        layout: "/admin"
    },
    {
        path: "/Comment",
        name: "Commentaire",
        icon: "fa-solid fa-comments",
        component: CommentPage,
        layout: "/admin",
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/Sim",
        name: "Sim",
        icon: "nc-icon nc-chat-round",
        component: Sim,
        layout: "/admin"
    },
    {
        invisible: true,
        path: "/testK1",
        name: "testK1",
        icon: "nc-icon nc-chat-round",
        component: testK1,
        layout: "/admin",
        background: "red"
    },
    {

        path: "/dashboard",
        name: "Dashboard",
        icon: "fa-solid fa-truck-pickup",
        component: UserDashboard,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        path: "/FleetVisualization",
        name: "Fleet",
        icon: "fa-solid fa-location-dot",
        component: FleetVisualization,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        path: "/DashboardSecurite",
        name: "Safety",
        icon: "fa-solid fa-triangle-exclamation",
        component: DashboardSecurite,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        path: "/DashboardProduction",
        name: "Production",
        icon: "fa-solid fa-chart-column",
        component: DashboardProduction,
        layout: "/user",
        construction: false,
        subMenuItems: [
            { layout: '/user', path: '/dashboard/production/daily', icon: 'fa-solid fa-calendar', name: 'Daily', component: DashboardProductionV2, construction: false },
            { layout: '/user', path: '/dashboard/production/cumulative', icon: 'fa-solid fa-layer-group', name: 'Cumulative', component: DashboardProductionCumulative, construction: false },
            // { layout: '/user', path: '/dashboard/production/pausepanel', icon: 'fa-regular fa-clock', name: 'Arret', component: DashboardProductionStop, construction: false, invisible: true },
        ],
    },
    {
        path: "/Device",
        name: "Device",
        icon: "fa-solid fa-tablet-button",
        component: Device,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        path: "/replay",
        name: "Replay",
        icon: "fa-solid fa-play",
        component: Replay,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },

    {
        path: "/Settings",
        name: "Settings",
        icon: "fa-solid fa-gear",
        component: Setting,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/map",
        name: "EditWorksite",
        icon: "nc-icon nc-chart-pie-35",
        component: EditWorksite,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/MovingDanger",
        name: "MovingDanger",
        icon: "nc-icon nc-chart-pie-35",
        component: MovingDanger,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/Rotation",
        name: "Rotation",
        icon: "nc-icon nc-chart-pie-35",
        component: Rotation,
        layout: "/user",
        construction: false,
        subMenuItems: [],
    },
    {
        invisible: true,
        path: "/FilteredMovingDanger",
        name: "Filtered Moving Danger",
        icon: "nc-icon nc-chart-pie-35",
        component: FilteredMovingDanger,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/ManageRotationV2",
        name: "ManageRotationV2",
        icon: "nc-icon nc-chart-pie-35",
        component: ManageRotationV2,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/HeatMapSpeed",
        name: "HeatMapSpeed",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapSpeed,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/HeatMapPedestrian",
        name: "HeatMapPedestrian",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertPedestrian,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/HeatMapHeavyWeight",
        name: "HeatMapHeavyWeight",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertHeavyWeight,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/HeatMapVehicle",
        name: "HeatMapVehicle",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertVehicle,
        layout: "/user",
        construction: false
    },
    {
        invisible: true,
        path: "/HeatMapMachine",
        name: "HeatMapMachine",
        icon: "nc-icon nc-chart-pie-35",
        component: HeatMapAlertMachine,
        layout: "/user",
        construction: false
    },
];

if (sessionStorage.getItem('isConnected') === "Connecté" && sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
    dashboardRoutes = dashboardRoutes.filter(route => route.layout === "/admin" || route.layout === "");
}
else if (sessionStorage.getItem('isConnected') === "Connecté" && sessionStorage.getItem("Roles").includes("ROLE_USER"))
    dashboardRoutes = dashboardRoutes.filter(route => route.layout === "/user" || route.layout === "");

export default { dashboardRoutes };
