import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import axios from "axios";
import Loader from 'Public/images/loader.gif';
import {
    MenuItem, Select, Checkbox, FormControl, InputLabel, List, ListItem, ListItemText, ListItemSecondaryAction,
    FormGroup, FormControlLabel, Typography, Button, Box, Grid, TextField
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { convertDuration, formatDateExcel, parseNumber, getWeekNumber, getMonthNumber, getNameByDateAndSerial } from "Components/utils/utils"

const apiUrl = process.env.REACT_APP_API_URL;
// const WS_URL = process.env.REACT_APP_WS_URL;

function DashboardProductionStop() {
    const [Worksite, setWorksite] = useState([]);
    const [devices, setDevices] = useState([]);
    const [serials, setSerials] = useState([]);
    const [devicesName, setDevicesName] = useState([]);
    // const [filteredDevices, setFilteredDevices] = useState([]);
    const [selectedStop, setSelectedStop] = useState('');
    const [checkedDevices, setCheckedDevices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [isLoading, setIsLoading] = useState(true);

    /* (arrêt qualité, panne, déplacement, intempérie, entretien, sécurité, arrêt divers) */
    const [stopList, setStopList] = useState([
        { id: 1, name: "Quality Stop", description: "Arrêt qualité" },
        { id: 2, name: "Breakdown", description: "Panne" },
        { id: 3, name: "Movement", description: "Déplacement" },
        { id: 4, name: "Weather", description: "Intempérie" },
        { id: 5, name: "Maintenance", description: "Entretien" },
        { id: 6, name: "Safety", description: "Sécurité" },
        { id: 7, name: "Other Stops", description: "Arrêt divers" }
    ]);

    useEffect(() => {
        const fetchWorksiteData = async () => {
            const response = await axios.get(`${apiUrl}/api/user/worksite`, {
                headers: { "x-access-token": sessionStorage.getItem("token") },
            });
            const sortedWorksite = response.data.sort((a, b) => a.worksiteid - b.worksiteid);
            setWorksite(sortedWorksite);
        };

        const fetchDevices = async () => {
            const role = sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? 'admin' : 'user';
            const response = await axios.get(`${apiUrl}/api/${role}/device`, {
                headers: { "x-access-token": sessionStorage.getItem("token") },
            });
            setDevices(response.data);
            console.log("devices", response.data);
            setSerials(response.data.map((item) => item.username.trim()));
        };

        fetchWorksiteData();
        fetchDevices();
        const today = new Date();
        setSelectedStartDate(today);
    }, []);

    useEffect(() => {
        const fetchDeviceNames = async () => {
            const promises = serials.map(serial =>
                getNameByDateAndSerial(selectedStartDate, serial).then(name => ({ serial, name }))
            );
            const results = await Promise.all(promises);
            const names = results.reduce((acc, { serial, name }) => ({ ...acc, [serial]: name }), {});
            // console.log("names: ", names);
            setDevicesName(names);
        };

        if (serials.length > 0) {
            fetchDeviceNames();
        }
    }, [serials])

    useEffect(() => {
        if (Object.keys(devicesName).length > 0) {
            console.log("devicesName", devicesName);
            setIsLoading(false);
        }
    }, [devicesName])

    const handleDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleStopChange = (event) => {
        setSelectedStop(event.target.value);
    };

    const handleDeviceCheck = (deviceId) => {
        setCheckedDevices(prev => {
            if (prev.includes(deviceId)) {
                return prev.filter(id => id !== deviceId);
            } else {
                return [...prev, deviceId];
            }
        });
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setCheckedDevices(devices.map(device => device.username)); // ou l'identifiant unique de vos devices
        } else {
            setCheckedDevices([]);
        }
    };

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const filteredDevices = devices.filter(device =>
        devicesName[device.username]?.toLowerCase().includes(searchTerm) ||
        device.username.toLowerCase().includes(searchTerm)
    );

    const handleSaveChanges = async () => {
        // A voir le payload à envoyer, stopReason + liste devices ?
        const payload = {
            stopReasonId: selectedStop,
            deviceUsernames: checkedDevices
        };

        try {
            // route API à créer. productionstate?
            const response = await axios.post(`${apiUrl}/api/${role}/productionstate`, payload, {
                headers: {
                    "x-access-token": sessionStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Save successful:', data);
            setCheckedDevices([]);
            alert('Changes saved successfully!');
        } catch (error) {
            console.error('Failed to save changes:', error);
            alert('Failed to save changes.');
        }
    };


    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="stop-select-label">Stop Reason</InputLabel>
                                <Select
                                    labelId="stop-select-label"
                                    id="stop-select"
                                    value={selectedStop}
                                    label="Stop Reason"
                                    onChange={handleStopChange}
                                >
                                    {stopList.map((stop) => (
                                        <MenuItem key={stop.id} value={stop.id}>{stop.description}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" sx={{ ml: 0.5, mb: -0.5, color: 'text.secondary' }}>
                                Arrêt depuis :
                            </Typography>
                            <DatePicker
                                selected={selectedStartDate}
                                onChange={handleDateChange}
                                dateFormat="MMMM d, yyyy hh:mm aa"
                                maxDate={new Date()}
                                todayButton="Today"
                                showTimeSelect
                                timeFormat="HH:mm"
                                customInput={<input type="text" className="form-control" style={{ width: '105%', padding: '10px' }} />}
                            />
                        </Grid>
                        {(selectedStop && !isLoading) &&
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Search Devices"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </Grid>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {isLoading && selectedStop ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <img src={Loader} alt="Chargement..." style={{ display: 'block', margin: 'auto', height: '20%' }} />
                        </Box>
                    ) : selectedStop && (
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" gutterBottom>
                                    Assign Stop Reason to Devices
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle1" sx={{ mr: 1, color: 'primary.main' }}>
                                        Select All
                                    </Typography>
                                    <Checkbox
                                        onChange={handleSelectAll}
                                        style={{ marginRight: '5px' }}
                                        checked={checkedDevices.length === (filteredDevices.length ? filteredDevices.length : devices.length)}
                                    />
                                </Box>
                            </Box>
                            <List dense>
                                {(filteredDevices.length ? filteredDevices : devices).map((device) => {
                                    const hardwareType = device.hardware_type ? device.hardware_type.nom_hardware_type :
                                        (device.id_hardware_type === 1 ? "Engin" : "Unknown Type");

                                    return (
                                        hardwareType.startsWith("Engin") ? (
                                            <ListItem key={device.username}>
                                                <ListItemText primary={devicesName[device.username]} secondary={`Serial: ${device.username}`} />
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => handleDeviceCheck(device.username)}
                                                        checked={checkedDevices.includes(device.username)}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ) : null
                                    );
                                })};
                            </List>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveChanges}
                                >
                                    Save Changes ({checkedDevices.length})
                                </Button>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default DashboardProductionStop;